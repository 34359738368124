<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex flex-row align-items-center justify-start pb-0">
        <v-icon>mdi-plus-circle</v-icon>
        <h4 class="ml-3 mb-0">เพิ่ม Visit ใหม่</h4>
      </div>

      <div class="d-flex flex-row align-end justify-start mb-1">
        <v-stepper
          v-model="e6"
          vertical
          class="border-inform-visit"
        >
          <v-stepper-step
            :complete="e6 > 1"
            step="1"
          >
            วันที่
            <small>ลงวันที่และวัดความดัน</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-menu
                  ref="menuVisitDate"
                  v-model="menuVisitDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedVisitdateFormatted"
                      label="วันที่*"
                      placeholder="ระบุวันที่"
                      append-outer-icon="mdi-calendar-month"
                      readonly
                      required
                      @click:append-outer="menuVisitDate = true"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="inputCustomer.visitdate"
                    :min="moment().format('YYYY')"
                    no-title
                    @change="menuVisitDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" class="py-0">
                <v-checkbox
                  v-model="inputCustomer.casereview"
                  label="เคสรีวิว"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" class="pt-0">
                <v-text-field
                  v-model="inputCustomer.bpvalue"
                  :rules="inputRules"
                  label="BP*"
                  placeholder="ระบุ BP"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" class="pt-0 mb-3">
                <v-text-field
                  v-model="inputCustomer.bwvalue"
                  :rules="inputRules"
                  label="BW*"
                  placeholder="ระบุ BW"
                  suffix="กิโลกรัม"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              color="primary"
              @click="e6 = 2"
            >
              ถัดไป
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="e6 > 2"
            step="2"
          >
            การรักษา
            <small>รายการหัตถการ</small>
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  v-model="inputCustomer.consult"
                  label="ปรึกษา"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" class="pt-0 d-flex flex-column">
                <span>มัดจำ/ซื้อวงเงิน</span>
                <v-select
                  :items="itemsPromotion"
                  v-model="inputCustomer.promotion"
                  label="โปรโมชัน*"
                  no-data-text="ยังไม่มีข้อมูล"
                  :rules="selectRules"
                  required
                ></v-select>
                <v-select
                  :items="itemsPackage"
                  v-model="inputCustomer.package"
                  label="แพคเกจ*"
                  no-data-text="ยังไม่มีข้อมูล"
                  :rules="selectRules"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" class="pt-0">
                <v-select
                  :items="itemsOperative"
                  v-model="inputCustomer.operative"
                  label="ทำหัตถการ*"
                  no-data-text="ยังไม่มีข้อมูล"
                  :rules="selectRules"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" class="pt-0">
                <span>ของแถม</span>
                <v-radio-group
                  v-model="inputCustomer.giveaway"
                  row
                >
                  <v-radio
                    label="ทำเลย"
                    value="giveaway1"
                  ></v-radio>
                  <v-radio
                    label="ฝากไว้ก่อน"
                    value="giveaway2"
                  ></v-radio>
                  <v-radio
                    label="ทำที่ฝากไว้"
                    value="giveaway3"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" class="pt-0">
                <v-menu
                  ref="menuFollowupDate"
                  v-model="menuFollowupDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedFollowupdateFormatted"
                      label="วันนัดติดตามผล*"
                      placeholder="ระบุวันนัดติดตามผล"
                      append-outer-icon="mdi-calendar-month"
                      readonly
                      required
                      @click:append-outer="menuFollowupDate = true"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="inputCustomer.followupdate"
                    :min="moment().format('YYYY')"
                    no-title
                    @change="menuFollowupDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" class="pt-0">
                <v-text-field
                  v-model="inputCustomer.followuptext"
                  :rules="inputRules"
                  label="นัดเพื่อ*"
                  placeholder="ระบุรายละเอียดการนัด"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" class="pt-0">
                <v-menu
                  ref="menuCallupDate"
                  v-model="menuCallupDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedCallupdateFormatted"
                      label="วันที่นัดเพื่อโทรครั้งใหม่*"
                      placeholder="ระบุวันที่นัดเพื่อโทรครั้งใหม่"
                      append-outer-icon="mdi-calendar-month"
                      readonly
                      required
                      @click:append-outer="menuCallupDate = true"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="inputCustomer.callupdate"
                    :min="moment().format('YYYY')"
                    no-title
                    @change="menuCallupDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" class="pt-0">
                <v-text-field
                  v-model="inputCustomer.calluptext"
                  :rules="inputRules"
                  label="นัดเพื่อ*"
                  placeholder="ระบุรายละเอียดการนัด"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" class="pt-0 mb-3">
                <v-text-field
                  v-model="inputCustomer.casenote"
                  label="ลงสมุดบันทึกเคส"
                  placeholder="ระบุรายละเอียดบันทึกเคส"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              color="primary"
              @click="e6 = 3"
            >
              ถัดไป
            </v-btn>
            <v-btn
              text
              @click="e6 = 1"
            >
              ย้อนกลับ
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="e6 > 3"
            step="3"
          >
            ราคา
            <small>มัดจำ/เพิ่มวงเงิน</small>
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" class="d-flex flex-column">
                <span>เคสมัดจำ</span>
                <v-text-field
                  v-model="inputCustomer.depositbalance"
                  :rules="inputRules"
                  label="ยอดเงิน*"
                  placeholder="ระบุยอดเงิน"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" class="pt-0 d-flex flex-column">
                <span>ช่องทาง</span>
                <v-select
                  :items="itemsPaymentMethod"
                  v-model="inputCustomer.paymentmethod"
                  label="ช่องทางการชำระเงิน*"
                  no-data-text="ยังไม่มีข้อมูล"
                  :rules="selectRules"
                  required
                ></v-select>
                <div v-if="inputCustomer.paymentmethod && inputCustomer.paymentmethod !== 'เงินสด'" class="d-flex flex-row">
                  <v-select
                    :items="itemsBank"
                    v-model="inputCustomer.bankpayment"
                    label="ธนาคาร*"
                    no-data-text="ยังไม่มีข้อมูล"
                    :rules="selectRules"
                    required
                  ></v-select>
                  <v-menu
                    ref="menuPaymentDepositTime"
                    v-model="menuPaymentDepositTime"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="inputCustomer.paiddeposittime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="inputCustomer.paiddeposittime"
                        label="ลงเวลา"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menuPaymentDepositTime"
                      v-model="inputCustomer.paiddeposittime"
                      format="24hr"
                      full-width
                      @click:minute="$refs.menuPaymentDepositTime.save(inputCustomer.paiddeposittime)"
                    ></v-time-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" class="pt-0 d-flex flex-column">
                <span>เคสทำหัตถการ</span>
                <v-text-field
                  v-model="inputCustomer.operativebalance"
                  :rules="inputRules"
                  label="ยอดเงิน*"
                  placeholder="ระบุยอดเงิน"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="4" class="pt-0">
                <v-text-field
                  v-model="inputCustomer.lessdeposit"
                  :rules="inputRules"
                  label="หักมัดจำ*"
                  placeholder="ระบุยอดเงินหักมัดจำ"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="4" class="pt-0">
                <v-text-field
                  v-model="inputCustomer.lessdiscount"
                  :rules="inputRules"
                  label="หักส่วนลด*"
                  placeholder="ระบุยอดเงินหักส่วนลด"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="4" class="pt-0 d-flex flex-row align-baseline">
                <v-text-field
                  v-model="inputCustomer.deduction"
                  :rules="inputRules"
                  label="หักวงเงิน*"
                  placeholder="ระบุยอดเงินหักวงเงิน"
                  required
                ></v-text-field>
                <span>{{`(วงเงินคงเหลือ ${inputCustomer.remaindeduction || 0})`}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="4" class="pt-0 d-flex flex-row align-baseline">
                <v-text-field
                  v-model="inputCustomer.remaindeduction"
                  :rules="inputRules"
                  label="คงเหลือจ่าย*"
                  placeholder="ระบุยอดเงินคงเหลือจ่าย"
                  required
                ></v-text-field>
                <span>{{`(ชำระผ่านช่องทาง ${inputCustomer.paymentmethod || 'เงินสด'})`}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="4" class="pt-0">
                <v-text-field
                  v-model="inputCustomer.lessremaindeduction"
                  :rules="inputRules"
                  label="แบ่งชำระ*"
                  placeholder="ระบุยอดเงินแบ่งชำระ"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="4" class="pt-0">
                <v-text-field
                  v-model="inputCustomer.totallessremaindeduction"
                  :rules="inputRules"
                  label="ค้างชำระ*"
                  placeholder="ระบุยอดเงินค้างชำระ"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" class="pt-0 d-flex flex-column">
                <span>เพิ่มวงเงิน</span>
                <div class="d-flex flex-row align-baseline">
                  <v-text-field
                    v-model="inputCustomer.increaselimit"
                    :rules="inputRules"
                    label="ยอดเงิน*"
                    placeholder="ระบุยอดเงิน"
                    required
                  ></v-text-field>
                  <span>{{`(ชำระผ่านช่องทาง ${inputCustomer.paymentmethod || 'เงินสด'})`}}</span>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" class="pt-0 mb-3">
                <v-text-field
                  v-model="inputCustomer.afterincreaselimit"
                  :rules="inputRules"
                  label="ได้เครดิตวงเงิน*"
                  placeholder="ระบุยอดเครดิตวงเงิน"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              color="primary"
              @click="e6 = 4"
            >
              ถัดไป
            </v-btn>
            <v-btn
              text
              @click="e6 = 2"
            >
              ย้อนกลับ
            </v-btn>
          </v-stepper-content>

          <v-stepper-step step="4">
            ลูกค้า
            <small>ลายเซ็นลูกค้า</small>
          </v-stepper-step>
          <v-stepper-content step="4">
            <v-card
              color="grey lighten-1"
              class="mb-12"
              height="200px"
            ></v-card>
            <v-btn
              color="primary"
              @click="e6 = 5"
            >
              ถัดไป
            </v-btn>
            <v-btn
              text
              @click="e6 = 3"
            >
              ย้อนกลับ
            </v-btn>
          </v-stepper-content>

          <v-stepper-step step="5">
            ส่วนเคสรีวิว
            <small>ลายเซ็นลูกค้า</small>
          </v-stepper-step>
          <v-stepper-content step="5">
            <v-card
              color="grey lighten-1"
              class="mb-12"
              height="200px"
            ></v-card>
            <v-btn
              color="primary"
              @click="e6 = 6"
            >
              ถัดไป
            </v-btn>
            <v-btn
              text
              @click="e6 = 4"
            >
              ย้อนกลับ
            </v-btn>
          </v-stepper-content>

          <v-stepper-step step="6">
            Sender
            <small>มีให้เลือก</small>
          </v-stepper-step>
          <v-stepper-content step="6">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" class="mb-3">
                <v-select
                  :items="itemsSender"
                  v-model="inputCustomer.sender"
                  label="Sender*"
                  no-data-text="ยังไม่มีข้อมูล"
                  :rules="selectRules"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-btn
              color="primary"
              @click="e6 = 7"
            >
              ถัดไป
            </v-btn>
            <v-btn
              text
              @click="e6 = 5"
            >
              ย้อนกลับ
            </v-btn>
          </v-stepper-content>

          <v-stepper-step step="7">
            Code
            <small>มีให้เลือก</small>
          </v-stepper-step>
          <v-stepper-content step="7">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" class="mb-3">
                <v-select
                  :items="itemsCode"
                  v-model="inputCustomer.code"
                  label="Code*"
                  no-data-text="ยังไม่มีข้อมูล"
                  :rules="selectRules"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-btn
              color="primary"
              @click="e6 = 8"
            >
              ถัดไป
            </v-btn>
            <v-btn
              text
              @click="e6 = 6"
            >
              ย้อนกลับ
            </v-btn>
          </v-stepper-content>

          <v-stepper-step step="8">
            แต้มสะสม
            <small>แสดงผลแต้มสะสมของลูกค้า</small>
          </v-stepper-step>
          <v-stepper-content step="8">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  v-model="inputCustomer.increaseaccumulatedpoint"
                  :rules="inputRules"
                  label="เพิ่มแต้มสะสม*"
                  placeholder="ระบุแต้มสะสมที่เพิ่ม"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" class="pt-0 mb-3">
                <v-text-field
                  v-model="inputCustomer.decreaseaccumulatedpoint"
                  :rules="inputRules"
                  label="หักแต้มสะสม*"
                  placeholder="ระบุแต้มสะสมที่หัก"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              color="success"
              @click="e6 = 1"
            >
              บันทึก
            </v-btn>
            <v-btn
              text
              @click="e6 = 7"
            >
              ย้อนกลับ
            </v-btn>
          </v-stepper-content>
        </v-stepper>
        <div class="d-flex align-center justify-end">
          <v-btn
            color="success"
            @click="e6 = 1"
          >
            บันทึกและส่ง
          </v-btn>
          <v-btn
            color="error"
            text
            @click="$router.go(-1)"
          >
            ย้อนกลับ
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      e6: 1,
      menuVisitDate: false,
      menuFollowupDate: false,
      menuCallupDate: false,
      menuPaymentDepositTime: false,
      inputCustomer: {
        visitdate: new Date().toISOString().substr(0, 10),
        casereview: false,
        giveaway: 'giveaway1',
        followupdate: new Date().toISOString().substr(0, 10),
        callupdate: new Date().toISOString().substr(0, 10),
        paiddeposittime: null
      },
      inputRules: [
        v => !!v || 'กรุณากรอกข้อมูล'
      ],
      selectRules: [
        v => !!v || 'กรุณาเลือกข้อมูล'
      ],
      itemsPromotion: ['ลดริ้วรอย', 'ปรับรูปหน้า', 'แก้ไขแก้มหย่อนคล้อย', 'ปรับผิวขาว', 'กระชับสัดส่วน', 'ลดสิว', 'ลดฝ้ากระ', 'แก้ไขใต้ตาคล้ำ/ลึก', 'แก้ไขหน้าตอบ'],
      itemsPackage: ['ลดริ้วรอย', 'ปรับรูปหน้า', 'แก้ไขแก้มหย่อนคล้อย', 'ปรับผิวขาว', 'กระชับสัดส่วน', 'ลดสิว', 'ลดฝ้ากระ', 'แก้ไขใต้ตาคล้ำ/ลึก', 'แก้ไขหน้าตอบ'],
      itemsOperative: ['ลดริ้วรอย', 'ปรับรูปหน้า', 'แก้ไขแก้มหย่อนคล้อย', 'ปรับผิวขาว', 'กระชับสัดส่วน', 'ลดสิว', 'ลดฝ้ากระ', 'แก้ไขใต้ตาคล้ำ/ลึก', 'แก้ไขหน้าตอบ'],
      itemsPaymentMethod: ['เงินสด', 'โอนเงิน', 'บัตรเครดิต', 'โอนบริษัท'],
      itemsBank: ['SCB', 'KBank', 'KTC', 'TMB', 'UOB', 'CITI', 'Thanachart'],
      itemsSender: ['A', 'K', 'B', 'R'],
      itemsCode: ['W', 'F', 'B']
    }
  },
  computed: {
    computedVisitdateFormatted () {
      const self = this
      return self.inputCustomer.visitdate ? moment(self.inputCustomer.visitdate).format('DD/MM/YYYY') : ''
    },
    computedFollowupdateFormatted () {
      const self = this
      return self.inputCustomer.followupdate ? moment(self.inputCustomer.followupdate).format('DD/MM/YYYY') : ''
    },
    computedCallupdateFormatted () {
      const self = this
      return self.inputCustomer.callupdate ? moment(self.inputCustomer.callupdate).format('DD/MM/YYYY') : ''
    }
  }
}
</script>

<style scoped>
@import '../../../styles/Customer.css';

.v-stepper {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  box-shadow: none !important;
}

.v-stepper--vertical .v-stepper__step {
  padding: 24px 0 16px 0 !important;
}

.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin: -8px -36px -16px 12px !important;
}
</style>
